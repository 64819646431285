// app/javascript/controllers/report_form_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "form" ]

  connect() {
  }

  toggleForm() {
    const selectedType = this.element.querySelector('#report_type_select').value.replace(/\s+/g, '-').toLowerCase();
    this.formTargets.forEach((form) => {
      form.style.display = form.id === `${selectedType}-form` ? 'block' : 'none';
    });
  }
}
